module.exports.backend = {
    apiUrl: "https://interwin-scrapper.venturing.com.ar/Interwin-Scrapper/api",
};

module.exports.url="https://www.interwin.com.ar"

module.exports.captcha = "6LemO8woAAAAAJuS_8fXFTM1NUqOyQDRwzMgYkbw";

module.exports.invisibleCaptcha =  "6LdVSbkpAAAAAKpkgt1HGI_ompNC7V_FvsCPCRih";

module.exports.GTM_ID='GTM-W67VHR9J'

module.exports.buildTimeoutInSeconds = 600;
